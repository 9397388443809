@import "forms";
@import "header";
@import "footer";
@import "home";
@import "page";
@import "posts";
@import "products";
@import "contrast";
@import "app-engemix";
@import "blog-sidebar";

// General
main {
  padding-top: 90px;
  min-height: 85vh;
}

body {
  overflow-x: hidden;
  color: #434343;
}

.cursor-pointer {
  cursor: pointer;
}

.card {
  border: none;
  background-color: transparent;
  border-radius: 0;

  .card-img {
    border-radius: 0;
  }
}

.card-link {
  @extend .position-relative;
  border-radius: 20px;

  .card-img {
    border-radius: 20px;
    transition: all ease 0.2s;
  }

  .card-img-overlay {
    z-index: 2;
  }

  .card-info {
    font-size: 0.8rem;
    font-weight: 900;

    h2 {
      font-size: inherit;
      font-weight: 900;
    }
  }

  &::before {
    content: ' ';
    border-radius: 20px;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &:hover {
    .card-info {
      text-decoration: underline;
    }

    .card-img {
      transform: scale(1.1);
    }
  }

  @media (min-width: 768px) {
    border-radius: 30px;

    .card-img {
      border-radius: 30px;
    }

    .card-info {
      font-size: 1.5rem;
    }

    &::before {
      border-radius: 30px;
    }
  }

  @media (min-width: 992px) {
    border-radius: 40px;

    .card-img {
      border-radius: 40px;
    }

    .card-info {
      font-size: 1.5rem;
      font-weight: 900;
    }

    &::before {
      border-radius: 40px;
    }
  }
}

.btn-primary-inverse {
  color: $primary;
  background-color: #fff;

  &:hover {
    color: $primary;
    text-decoration: underline;
  }
}

.btn {
  @extend .rounded-0;
  @extend .pr-3;
  @extend .pl-3;
  font-weight: 900;

  &.btn-bg-none {
    @extend .px-0;
  }

  &:hover {
    text-decoration: underline;
  }
}

.section-title {
  font-size: 1rem;
  font-weight: 900;
  @media (min-width: 768px) {
    font-size: 1.15rem;
  }
}

.section-subtitle {
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1.2;
  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
}
