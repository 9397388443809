.products-section {
  .products-list {
    @extend .px-0;

    .product {
      .product-link {
        @extend .position-relative;
        border-radius: 20px;

        .card-img {
          border-radius: 20px;
          transition: all ease 0.2s;
        }

        .card-img-overlay {
          z-index: 2;
        }

        .product-info {
          font-size: 0.8rem;
          font-weight: 900;

          h2 {
            font-size: inherit;
          }
        }

        &::before {
          content: ' ';
          border-radius: 20px;
          background: rgb(0, 0, 0);
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.4) 45%, rgba(0, 0, 0, 0) 65%);
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &:hover {
          .product-info {
            text-decoration: underline;
          }

          .card-img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .products-list {
      .product {
        .product-link {
          border-radius: 30px;

          .card-img {
            border-radius: 30px;
          }

          .product-info {
            font-size: 1.5rem;
          }

          &::before {
            border-radius: 30px;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    .products-list {
      .product {
        .product-link {
          border-radius: 40px;

          .card-img {
            border-radius: 40px;
          }

          .product-info {
            font-size: 1.5rem;
            font-weight: 900;
          }

          &::before {
            border-radius: 40px;
          }
        }
      }
    }
  }
}

.product {
  .internal-hero {
    margin-bottom: 0 !important;
    background-position: center;

    &::before {
      background: rgb(36, 36, 36);
      background: linear-gradient(14deg, rgba(36, 36, 36, 1) 0%, rgba(36, 36, 36, 0.8155637254901961) 27%, rgba(36, 36, 36, 0.4990371148459384) 58%);
    }
  }

  .product-description {
    font-size: 1.3rem;
    line-height: 2;
    font-weight: 300;

    .product-type {
      @extend .px-0;

      li {
        list-style: none;
        font-weight: 900;
      }
    }
  }

  @media (min-width: 992px) {
    .product-features {
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        width: 1000vw;
        height: 100%;
        background-color: #fff;
        top: 0;
        right: 0;
        left: 100%;
      }
    }
  }
}
