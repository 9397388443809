.contrast {
  img {
    filter: grayscale(1) !important;
  }

  .text-primary {
    color: black !important;
  }

  .bg-primary {
    background-color: #000 !important;
  }

  .btn-primary {
    background-color: #000 !important;
  }

  .btn-primary-inverse {
    color: #000 !important;
  }

  .header {
    background-color: #333 !important;
  }

  .dropdown-menu {
    background-color: #000 !important;
  }

  .hero {
    filter: grayscale(1) !important;
  }

  .internal-hero {
    filter: grayscale(1) !important;

    &::before {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.65) 45%, rgba(0, 0, 0, 0) 65%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  .simulator {
    .calculadora {
      .calculadora-link {
        background-color: #fff;

        .icon {
          filter: grayscale(1);
        }

        &.is-active {
          background-color: black !important;
        }
      }

      .simulator-result {
        @extend .border-0;
        @extend .p-0;
        @extend .text-primary;
        background: none;
        font-size: 2.5rem;
        font-weight: 900;

        &::after {
          content: 'm³';
          display: inline;
        }
      }
    }
  }

  .duvidas-frequentes {
    .collapse-wrapper {
      border: 1px solid #acacac;
    }

    .collapse-btn {
      color: #fff !important;
      filter: grayscale(1) !important;
      background-color: #000;
    }

    .collapse-content {
      border-top: 1px solid #acacac;
      background-color: #fff;
    }
  }

  .footer {
    filter: grayscale(1) !important;
  }
}
