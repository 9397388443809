.blog-hero-mascote {
  position: absolute;
  right: -5vw;
  bottom: 0;

  .blog-hero-mascote-img {
    max-width: 90%;
  }

  @media (min-width: 576px) {
    right: 0;

    .blog-hero-mascote-img {
      max-width: 80%;
    }
  }

  @media (min-width: 992px) {
    .blog-hero-mascote-img {
      max-width: 75%;
    }
  }

  @media (min-width: 1399px) {
    .blog-hero-mascote-img {
      max-width: 90%;
    }
  }
}

.post-title {
  font-weight: bold;
}

.post-date {
  font-weight: bold;
}

.posts-page {
  .internal-hero {
    .row {
      padding-bottom: 0 !important;
    }

    .section-subtitle {
      text-shadow: 1px 1px 10px #222;
    }
  }

  .posts-list {
    .post {
      .post-image {
        border-radius: 25px;
      }

      .post-excerpt {
        a {
          color: inherit;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .internal-hero {
      .row {
        height: 55vh;
        max-height: 500px;
      }

      .section-subtitle {
        font-size: 4.5rem;
      }
    }
  }
}

.post-footer {
  .post-author {
    border-top-width: 2px !important;
  }

  .post-share {
    .post-share-items {
      & > div,
      & > span {
        display: inline-block;
        margin-left: 8px;
        height: 26px;
        line-height: 20px;
        overflow: hidden;
      }

      .share-email,
      .share-whatsapp {
        & > a {
          color: #fff;
          line-height: 20px;
          padding: 0 5px;
          border-radius: 3px;
          height: 20px;
          display: inline-block;
          text-transform: none;
          font-size: 13px;

          i {
            font-size: 16px;
            margin-top: 2px;
            float: left;
            margin-right: 5px;
          }
        }
      }

      .share-whatsapp {
        & > a {
          background: #4dc247;
          color: #fff;
          line-height: 20px;
          padding: 0 5px;
          border-radius: 3px;
          height: 20px;
          display: inline-block;
          text-transform: none;
          font-size: 13px;

          i {
            font-size: 16px;
            margin-top: 2px;
            float: left;
            margin-right: 5px;
          }
        }
      }

      .share-email {
        & > a {
          background: #2b445d;
        }
      }
    }
  }
}

article.post {
  img {
    max-width: 100% !important;
  }
}

.post-navigation {
  .btn {
    border-radius: 25px !important;
  }
}
