.header {
  z-index: 100;
  // position: relative;
  background-color: #ec1b22;

  .call-obra {
    @extend .bg-primary;

    #link-top-quote {
      font-size: 1.2rem;
    }
  }

  .navbar {
    justify-content: space-between;

    .navbar-brand {
      &.vcimentos {
        max-width: 100px;
      }

      &.engemix {
        max-width: 80px;
      }
    }

    #navbar-header {
      height: 86.5vh;
      font-size: 1.4rem;

      .menu-secondary {
        position: absolute;
        bottom: 7.5vh;
        width: 100%;

        .menu-institucional {
          font-size: 1rem;
        }
      }
    }
  }

  .menu-secondary {
    .menu-a11y {
      button {
        background: none;
        color: white;
        border: none;
        font-size: 1rem;
      }
    }
  }

  @media (min-width: 768px) {
    font-size: 1rem;

    .navbar {
      justify-content: space-between;

      .navbar-brand {
        &.vcimentos {
          max-width: 110px;
        }

        &.engemix {
          max-width: 90px;
        }
      }

      #navbar-header {
        height: 91vh;
      }
    }
  }

  @media (min-width: 992px) {
    font-size: 1rem;

    .navbar {
      justify-content: space-between;

      .navbar-brands {
        width: auto;
        flex-basis: auto;
      }

      .navbar-brand {
        &.vcimentos {
          max-width: 110px;
        }

        &.engemix {
          max-width: 90px;
        }
      }

      #navbar-header {
        height: auto;
        font-size: 0.7rem;
      }
    }
  }

  @media (min-width: 1200px) {
    font-size: 1rem;

    .navbar {
      justify-content: space-between;

      .navbar-brands {
        width: auto;
        flex-basis: auto;
      }

      .navbar-brand {
        &.vcimentos {
          max-width: 110px;
        }

        &.engemix {
          max-width: 90px;
        }
      }

      #navbar-header {
        height: auto;
        font-size: 0.9rem;
      }
    }
  }
}

#menu-menu-cabecalho {
  height: 80vh;

  li {
    @extend .px-2;
    @extend .py-2;

    a {
      color: white;
    }

    .dropdown-menu {
      @extend .m-0;
      @extend .border-0;
      @extend .row;
      @extend .d-none;
      @extend .rounded-0;
      width: 100%;
      background-color: #ee1c24;
      font-size: 1.2rem;

      li {
        @extend .col-lg-4;

        &:first-child {
          @extend .offset-lg-4;
        }
      }

      &::before,
      &::after {
        content: ' ';
        width: 50vw;
        position: absolute;
        height: 100%;
        top: 0;
        left: 99%;
        background-color: inherit;
      }

      &::after {
        left: auto;
        right: 100%;
      }

      &.show {
        @extend .d-flex;
      }
    }
  }
  @media (min-width: 768px) {
    height: 88vh;
  }

  @media (min-width: 992px) {
    height: auto;

    li {
      .dropdown-menu {
        background-color: $primary;
        font-size: 0.9rem;
      }
    }
  }

  @media (min-width: 1200px) {
    height: auto;

    li {
      .dropdown-menu {
        background-color: $primary;
        font-size: 1rem;
      }
    }
  }
}
