.home {
  // Hero
  .hero {
    background-image: url('../img/2021-10-20-bg-hero-mobile.webp');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    height: 75vh;

    .title {
      font-weight: bold;
      line-height: 1.2;
      font-size: 1.8rem;

      .big {
        font-size: 2.2rem;
      }
    }

    @media (min-width: 576px) {
      padding-top: 5.5rem;
    }

    @media (min-width: 768px) {
      height: 90vh;
      padding-top: 0;

      .title {
        font-weight: bold;
        line-height: 1.2;
        font-size: 3rem;

        .big {
          font-size: 4.5rem;
        }
      }
    }

    @media (min-width: 992px) {
      background-image: url('../img/2021-10-28-bg-hero-desktop.webp');
      padding-top: 5.5rem !important;
      height: 31rem;

      .hero-img {
        margin-top: 4rem;
        margin-left: -3rem;
      }

      .title {
        font-size: 2.2rem;

        .big {
          font-size: 3rem;
        }
      }

      .subtitle {
        font-size: 2.4rem;
        font-weight: 900;
        line-height: 1.2;
        position: relative;

        .phone-obra-number {
          font-size: 1.6rem;
          line-height: 0.45;
          position: absolute;
          top: 108px;
          padding-left: 6px;

          .number {
            font-size: 1rem;
            letter-spacing: 0.5rem;
            padding-left: 0.18rem;
            line-height: 1.4;
          }
        }
      }
    }

    @media (min-width: 1200px) {
      padding-top: 8rem !important;
      height: 35.5rem;

      .title {
        font-size: 2.8rem;

        .big {
          font-size: 3.5rem;
        }
      }

      .subtitle {
        font-size: 2.4rem;
        font-weight: 900;
        line-height: 1.2;
      }
    }
  }

  // Benefits
  .benefits {
    .card {
      // @extend .px-3;
      background-color: transparent;

      .card-header {
        background-color: transparent;
        border-bottom: none;

        h4 {
          font-weight: 900;
        }
      }

      .card-body {
        background-color: transparent;
      }
    }
  }

  // Products

  .products {
    .card {
      height: 50vh;

      .card-content {
        border-radius: 40px;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .card-img {
        border-radius: 40px;
        max-height: 100%;
        object-fit: cover;
        object-position: left;
        transition: all ease 0.2s;
      }

      .card-img-overlay {
        h4 {
          font-weight: 900;
        }
      }

      &:hover {
        .card-img {
          transform: scale(1.1);
        }
      }
    }
    @media (min-width: 768px) {
      .card {
        height: 35vh;
      }
    }
    @media (min-width: 992px) {
      .card {
        height: 19rem;
      }
    }
  }

  // Quote
  .quote {
    .card {
      .card-img {
        object-fit: cover;
        object-position: left;
      }
    }
    @media (min-width: 768px) {
      .card {
        height: 32rem;
      }
    }
    @media (min-width: 992px) {
      .card {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgb(0, 0, 0);
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 15%, rgba(0, 0, 0, 0) 50%);
        }
      }
    }
  }

  .home-ads {
    .featured-ad{
      &-mobile {
        width: 340px;
        margin: 0 auto;
      }
      &-desktop {
        width: 950px;
        margin: 0 auto;
      }
    }
  }

  .section.video {
    .section-title {
      color: #fff;
    }

    .video-wrapper {
      border-radius: 16px;
    }
  }

  // Simulator
  .video,
  .simulator {
    .card {
      .card-img {
        object-fit: cover;
        object-position: left;
      }
    }
    @media (min-width: 768px) {
      .card {
        height: 32rem;
      }
    }
  }

  // Units and App

  .units-and-app {
    .card {
      height: 80vh;

      .card-img {
        object-fit: cover;
        object-position: left;
      }

      &.app-engemix {
        cursor: pointer;
      }
    }
    @media (min-width: 768px) {
      .card {
        height: 32rem;
      }
    }

    @media (min-width: 992px) {
      .app-link {
        opacity: 0.9;
        transition: all ease 0.3s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.video-wrapper {
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;

  embed,
  iframe,
  object {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
