// Internal Hero

.internal-hero {
  @extend .position-relative;
  @extend .text-white;
  @extend .mb-4;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  &::before {
    content: ' ';
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.4) 45%, rgba(0, 0, 0, 0) 65%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .row {
    @extend .pb-4;
    height: 22rem;
  }

  @media (min-width: 768px) {
    .row {
      height: 30rem;
    }
  }

  @media (min-width: 992px) {
    .row {
      height: 45vh;
    }
  }
}

// Default Page
.page {
  h2 {
    color: $primary;
  }

  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
}

// Quem Somos
.quem-somos {
  .internal-hero {
    background-image: url(../img/img-about-us@3x.webp);
  }

  @media (min-width: 768px) {
    .internal-hero {
      .row {
        height: 45rem;
      }
    }
  }

  @media (min-width: 992px) {
    .internal-hero {
      background-image: url(../img/img-about-us-desktop@3x.webp);

      .row {
        height: 45vh;
      }
    }
  }
}

// Unidades
.units {
  overflow-x: hidden;

  .page-header-no-hero {
    background-color: #fff;
    position: relative;

    &::before,
    &::after {
      content: ' ';
      width: 50vw;
      position: absolute;
      height: 100%;
      top: 0;
      left: 100%;
      background-color: inherit;
    }

    &::after {
      left: auto;
      right: 100%;
    }
  }

  .unit {
    box-shadow: 0 0 5px 0 rgba(2, 3, 2, 0.1);
    border: solid 0.5px #a3a3a3;
    border-bottom: 3px solid $primary;
    background-color: #fff;

    .unit-title {
      font-weight: 900;
    }

    .unit-link {
      font-weight: 900;
    }
  }
}

// Solicite um orçamento
.get-quote {
  @media (min-width: 992px) {
    .quote-img {
      object-fit: contain;
      object-position: top;
      width: auto;
    }

    .quote-bg {
      height: 100vh;
      top: 0;
    }
  }

  @media (min-width: 1400px) {
    .quote-img {
      object-fit: cover;
      object-position: center;
      width: auto;
    }
  }

  .gform_footer {
    .gform_button {
      @extend .btn;
      @extend .btn-primary;
    }
  }
}

// Fale com vendedor
.sellers {
  .seller-title {
    font-size: 1.5rem;
    font-weight: 900;
  }

  .seller-phones {
    @extend .px-0;
    column-count: 2;
    column-gap: 2rem;
    column-width: auto;
    list-style: none;

    // li {
    //   width: 33rem;
    // }
  }
}

.simulator {
  .calculadora {
    .calculadora-link {
      background-color: #fff;
      border-radius: 2rem;
      font-weight: 900;
      @extend .mb-3;
      @extend .text-primary;

      .icon {
        @extend .w-100;
        @extend .d-flex;
        height: 23vh;
        background-repeat: no-repeat;
        background-position: center;

        &.icon-laje {
          background-image: url(../img/icon-laje.webp);

          &.icon-laje-active {
            background-image: url(../img/icon-laje-active.webp);
          }
        }

        &.icon-pilar {
          background-image: url(../img/icon-pilar.webp);

          &.icon-pilar-active {
            background-image: url(../img/icon-pilar-active.webp);
          }
        }

        &.icon-viga {
          background-image: url(../img/icon-viga.webp);

          &.icon-viga-active {
            background-image: url(../img/icon-viga-active.webp);
          }
        }

        &.icon-calcada {
          background-image: url(../img/icon-calcada.webp);

          &.icon-calcada-active {
            background-image: url(../img/icon-calcada-active.webp);
          }
        }
      }

      &.is-active {
        background-color: $primary;
        @extend .text-white;

        &:hover,
        &:focus {
          @extend .text-white;
        }
      }
    }

    .col-form-label {
      font-weight: 900;
    }

    .simulator-result {
      @extend .border-0;
      @extend .p-0;
      @extend .text-primary;
      background: none;
      font-size: 2.5rem;
      font-weight: 900;

      &::after {
        content: 'm³';
        display: inline;
      }
    }
  }

  @media (min-width: 768px) {
    .calculadora {
      .calculadora-link {
        .icon {
          height: 13vh;
        }
      }

      .simulator-result {
        background: none;
        font-size: 2.5rem;
        font-weight: 900;
      }

      .simulator-result-label {
        font-size: 1.5rem;
        line-height: 1.2;
      }

      .calculadora-orcamento {
        .section-subtitle {
          font-size: 1.5rem;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .calculadora {
      .calculadora-link {
        .icon {
          height: 13vh;
        }
      }

      .simulator-result {
        background: none;
        font-size: 2.5rem;
        font-weight: 900;
      }

      .simulator-result-label {
        font-size: 1.4rem;
        line-height: 1.2;
      }

      .calculadora-orcamento {
        .section-subtitle {
          font-size: 1.2rem;
        }
      }
    }
  }
}

// Depoimentos
.testimonial {
  .testimonial-swiper-container {
    .owl-stage {
      padding-bottom: 2rem;
    }

    .owl-stage-outer {
      overflow: visible;
    }

    .item {
      padding-top: 2rem;
      box-shadow: 0 10px 12.5px 0 rgba(86, 86, 86, 0.2);
      background-color: #fff;
      border-radius: 20px;
      position: relative;

      .card-title {
        font-weight: 900;
      }

      &::before {
        width: 80px;
        height: 80px;
        box-shadow: inherit;
        border-radius: 15px;
        content: ' ';
        position: absolute;
        top: -45px;
        left: 15px;
        background-color: #fff;
        background-image: url(../svg/icon-testimonial.svg);
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  @media (min-width: 992px) {
    border-radius: 40px;
  }
}

// Duvidas Frequentes
.duvidas-frequentes {
  .collapse-wrapper {
    border: 1px solid #acacac;
  }

  .collapse-btn {
    width: 100%;
    text-align: left;
    border: none;
    padding: 15px 50px 15px 15px;
    cursor: pointer;
    color: #cc2228;
    font-weight: 700;
    background: #f7f7f7 url('../img/icon-collapse.webp') no-repeat center right 15px;
  }

  .collapse-btn-is-active {
    background-image: url('../img/icon-collapse-active.webp');
  }

  .collapse-content {
    display: none;
    border-top: 1px solid #acacac;
    background-color: #fff;
    padding: 20px;
  }

  .collapse-content p {
    margin: 0;
  }

  .collapse-btn-is-active + .collapse-content {
    display: block;
  }
}
