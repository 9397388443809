@font-face {
  font-family: 'Frutiger';
  src: url('../fonts/frutiger/frutiger57.eot');
  src:
    url('../fonts/frutiger/frutiger57.eot?#iefix') format('embedded-opentype'),
    url('../fonts/frutiger/frutiger57.woff') format('woff'),
    url('../fonts/frutiger/frutiger57.ttf') format('truetype'),
    url('../fonts/frutiger/frutiger57.svg#frutiger57') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Frutiger';
  src: url('../fonts/frutiger/frutiger77.eot');
  src:
    url('../fonts/frutiger/frutiger77.eot?#iefix') format('embedded-opentype'),
    url('../fonts/frutiger/frutiger77.woff') format('woff'),
    url('../fonts/frutiger/frutiger77.ttf') format('truetype'),
    url('../fonts/frutiger/frutiger77.svg#frutiger77') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fontawesome/fa-brands-400.eot");
  src:
    url("../fonts/fontawesome/fa-brands-400.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fontawesome/fa-brands-400.woff2") format("woff2"),
    url("../fonts/fontawesome/fa-brands-400.woff") format("woff"),
    url("../fonts/fontawesome/fa-brands-400.ttf") format("truetype"),
    url("../fonts/fontawesome/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fontawesome/fa-regular-400.eot");
  src:
    url("../fonts/fontawesome/fa-regular-400.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fontawesome/fa-regular-400.woff2") format("woff2"),
    url("../fonts/fontawesome/fa-regular-400.woff") format("woff"),
    url("../fonts/fontawesome/fa-regular-400.ttf") format("truetype"),
    url("../fonts/fontawesome/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fontawesome/fa-solid-900.eot");
  src:
    url("../fonts/fontawesome/fa-solid-900.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fontawesome/fa-solid-900.woff2") format("woff2"),
    url("../fonts/fontawesome/fa-solid-900.woff") format("woff"),
    url("../fonts/fontawesome/fa-solid-900.ttf") format("truetype"),
    url("../fonts/fontawesome/fa-solid-900.svg#fontawesome") format("svg");
}

.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}
