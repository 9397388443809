@import '../../../node_modules/animate.css/source/_vars';
@import '../../../node_modules/animate.css/source/_base';
@import '../../../node_modules/animate.css/source/fading_entrances/fadeIn';
@import '../../../node_modules/animate.css/source/fading_exits/fadeOut';

.app-engemix {
  .download-now {
    p {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1;

      &.download-now-sales {
        font-size: 1.15rem;
      }
    }
    @media (min-width: 768px) {
      p {
        font-size: 2rem;

        &.download-now-sales {
          font-size: 1.15rem;
        }
      }
    }
  }

  #carousel {
    background-color: #fff;

    .app-carousel-navigation {
      ul {
        margin: 0;
        padding: 0;

        li {
          font-size: 1.5rem;
          font-weight: bold;
          opacity: 0.3;
          padding: 6px 0;

          a {
            color: $secondary;

            &:hover {
              text-decoration: none;
            }
          }

          &::marker {
            font-size: 2rem;
          }

          &.active {
            opacity: 1;

            &::marker {
              color: $primary;
            }
          }
        }
      }

      @media (max-width: 767px) {
        li {
          display: none;

          &.active {
            display: block;
          }
        }
      }
    }

    .app-carousel {
      .item {
        .img-fluid {
          width: auto;
          max-height: 500px;
        }

        .carousel-item-title {
          text-align: left;
          font-size: 2rem;
          font-weight: bold;
          padding: 6px 0 35px;
          line-height: 1;
        }
      }

      .owl-dots {
        transform: rotate(90deg);
      }
    }
  }

  #highlights {
    background-image: url(../img/bg-app-quero-baixar.webp);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: auto;

    #video-first-steps {
      max-width: 470px;
    }
  }

  #testimonial {
    background-color: #fff;

    .section-title {
      font-size: 1.5rem;
    }

    @media (min-width: 768px) {
      .section-title {
        font-size: 2.5rem;
      }
    }
  }
}
