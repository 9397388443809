.footer {
  background-image: url(../img/background_footer.webp);
  background-repeat: repeat;
  background-position: top left;
  z-index: 10;
  overflow-x: hidden;

  a {
    @extend .text-white;
  }

  select {
    background-image: url(../img/seta-select-footer.webp);
    background-color: #15222e;
    color: white;
  }

  .nav {
    li {
      @extend .px-3;
      @extend .py-1;
    }

    &.footer-social {
      li {
        @extend .nav-item;
        @extend .p-1;
        font-size: 1.5rem;

        a {
          @extend .nav-link;
        }
      }
    }
  }

  .footer-credits {
    background-color: rgba($color: #000, $alpha: 0.26);
    font-size: 75%;
  }

  .floating-button {
    background-color: #25d366;
    width: 50vw;
    font-weight: 900;
    bottom: 0;
    position: fixed;
    z-index: 9999;
    right: 0;

    // &::before,
    // &::after {
    //   content: ' ';
    //   width: 50vw;
    //   position: absolute;
    //   height: 100%;
    //   top: 0;
    //   left: 100%;
    //   background-color: inherit;
    // }

    // &::after {
    //   left: auto;
    //   right: 100%;
    // }

    &.chat {
      background-color: $primary;
      left: 0;
    }
  }

  #floating-app {
    position: fixed;
    right: 30px;
    bottom: 163px;
    left: auto;

    a {
      display: block;
      margin-bottom: 5px;
    }
  }

  @media (min-width: 992px) {
    overflow-x: visible;

    .footer-social {
      justify-content: start !important;

      li:first-child {
        a {
          padding-left: 0;
        }
      }
    }

    .floating-button {
      width: 300px;
      right: 0;

      &.position-relative {
        position: absolute !important;
        right: 0;
        top: -40px;
        bottom: auto;

        &.chat {
          right: 305px;
        }
      }

      &::before,
      &::after {
        content: none;
      }

      &.chat {
        left: auto;
        right: 305px;
      }
    }

    #floating-app {
      a {
        transition: all 0.3s ease;
        opacity: 0.8;
  
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @media (min-width: 1599px) {
    .floating-button {
      right: 10vw;

      &.chat {
        right: calc(10vw + 305px);
      }
    }
  }
}

.page-template-template-orcamentos {
  .footer {
    position: relative;
  }
}
