.defaut-field {
  @extend .px-2;
  @extend .py-2;
  appearance: none;
  -webkit-appearance: none;
  box-shadow: 0 0 5px 0 rgba(2, 3, 2, 0.1);
  border: solid 0.5px #a3a3a3;
  background-color: #fff;
}

select {
  @extend .defaut-field;
  background-image: url(../img/seta-select.webp);
  background-repeat: no-repeat;
  background-position: right center;
}

.gform_wrapper {
  .validation_error {
    @extend .col-12;
    @extend .alert;
    @extend .alert-danger;
  }

  .validation_message {
    @extend .text-danger;
  }

  .gform_body {
    @extend .w-100;

    .gform_fields {
      @extend .p-0;
      @extend .row;

      .gfield {
        @extend .mb-4;
        list-style: none;

        input[type=text],
        input[type=email],
        input[type=phone],
        select,
        textarea {
          width: 100%;
          @extend .defaut-field;
        }

        .gfield_label {
          @extend .mb-2;
          font-weight: 900;
        }
      }
    }
  }

  .gform_footer {
    @extend .col;
    @extend .text-right;

    input[type=submit] {
      @extend .btn;
      @extend .btn-primary;
    }
  }
}
